<template>
  <div :key="componentKey">    

    <CToaster>
      <CToast 
        :show="(errorMsgs.length > 0)"
        color="danger"
        header="Error"
      >
        <ul class='p-2 m-0' v-for="msg in errorMsgs" :key="msg">
          <li>{{msg}}</li>
        </ul>
      </CToast>
    </CToaster>
    
    <CCard>
        <CCardHeader>
            <slot name="header">
            <CIcon name="cil-description"/>  {{ $options.name }} 
                <div class="card-header-actions">
                </div>
            </slot>
        </CCardHeader>
        <CCardBody>
            <CRow>
                <CCol class="col-sm-12">
                    <CSelect
                        label="Report Type"
                        :options="reportTypeOptions"
                        :value="reportType"
                        @update:value="selectReportType"
                    />
                </CCol>
                <CCol class="col-sm-12">
                    <CButton color="primary" size='sm' @click="exportReport">Export</CButton>
                </CCol>
            </CRow>
        </CCardBody>
    </CCard>
  </div>
</template>

<script>
  import HTTPRequest from "../../controllers/httpRequest.js";
  import { mapState } from "vuex";

  export default {
    name: "Reports",
    components: {  },
    computed: {
      ...mapState(["endPoint"])
    },
    mounted() {
     
    },
    data() {
      return {
          reportTypeOptions: ["Draft Bookings", "Provisional Bookings"],
          reportType: "Draft Bookings",
          errorMsgs: []
      };
    }, 
    methods: {
        selectReportType(value){
            this.reportType = value;
        },
        async exportReport(){
          if (this.reportType == "Draft Bookings") {
            let draft_event = await HTTPRequest.getRequest(this.endPoint, "/api/v1/reports/draft_bookings", null, null)
            if (draft_event.file_name) {
              window.open(this.endPoint+"/reports/"+draft_event.file_name);
            } else {
              this.errorMsgs.push("Error extracting file")
            }
          } else {
            //Provisional
            let draft_event = await HTTPRequest.getRequest(this.endPoint, "/api/v1/reports/provisional_bookings", null, null)
            if (draft_event.file_name) {
              window.open(this.endPoint+"/reports/"+draft_event.file_name);
            } else {
              this.errorMsgs.push("Error extracting file")
            }
          }
        },
    }
  };
  
</script>
<style lang='scss'>

</style>
